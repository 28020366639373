<template>
  <div class="meetSetting">
    <div class="item">
      <!-- 个人会议号 -->
      <div class="tit">{{ $t("login.selfNumber") }}</div>
      <div class="text">{{ userInfo.conferenceNo }}</div>
    </div>
    <div class="cell">
      <!-- 入会密码 -->
      <van-cell
        center
        size="32"
        title-style="fontSize:16px;"
        :title="$t('login.meetPwd')"
      >
        <template #right-icon>
          <van-switch
            v-model="passwordEnable"
            :active-value="1"
            :inactive-value="0"
            size="24"
          />
        </template>
      </van-cell>

      <van-field
        v-model="password"
        v-if="passwordEnable"
        type="password"
        :name="$t('login.password')"
        label=""
        :placeholder="$t('login.meetRequire')"
        :rules="[{ required: true, message: $t('login.inputPwsd') }]"
      />
    </div>
    <div class="cell">
      <van-cell
        center
        size="32"
        title-style="fontSize:16px;"
        :title="$t('login.mainPwd2')"
      >
        <template #right-icon>
          <van-switch
            v-model="ownerPasswordEnable"
            :active-value="1"
            :inactive-value="0"
            size="24"
          />
        </template>
      </van-cell>

      <van-field
        v-model="ownerPassword"
        v-if="ownerPasswordEnable"
        type="password"
        :name="$t('login.password')"
        label=""
        :placeholder="$t('login.mainRequire')"
        :rules="[{ required: true, message: $t('login.inputPwsd') }]"
      />
    </div>
    <div class="cell">
      <van-cell
        center
        size="32"
        title-style="fontSize:16px;"
        :title="$t('login.joinMeetok')"
      >
        <template #right-icon>
          <van-switch
            v-model="allowEarlyEntry"
            :active-value="1"
            :inactive-value="0"
            size="24"
          />
        </template>
      </van-cell>
    </div>
    <div class="cell">
      <van-cell
        center
        size="32"
        title-style="fontSize:16px;"
        :title="$t('login.mute')"
      >
        <template #right-icon>
          <van-switch
            v-model="muteJoinMeeting"
            :active-value="1"
            :inactive-value="0"
            size="24"
          />
        </template>
      </van-cell>
    </div>
    <div class="button">
      <van-button size="large" class="btn" @click="save" color="#1AB370">{{
        $t("login.sure")
      }}</van-button>
      <van-button
        size="large"
        @click="back"
        class="btn bottomBtn"
        type="default"
        >{{ $t("common.back") }}</van-button
      >
    </div>
  </div>
</template>

<script>
import { checkSmallPwd } from "@/utils/check";
export default {
  data() {
    return {
      password: "", // 会议密码
      passwordEnable: "", // 是否启用密码
      ownerPassword: "", // 主持人密码
      ownerPasswordEnable: "", // 是否启用主持人密码
      allowEarlyEntry: "", // 允许成员在主持人入会前加入会议
      muteJoinMeeting: "", // 成员入会时自动静音
    };
  },
  mounted() {
    this.password = this.userInfo.password;
    this.passwordEnable = this.userInfo.passwordEnable;
    this.ownerPassword = this.userInfo.ownerPassword;
    this.ownerPasswordEnable = this.userInfo.ownerPasswordEnable;
    this.allowEarlyEntry = this.userInfo.allowEarlyEntry;
    this.muteJoinMeeting = this.userInfo.muteJoinMeeting;
  },
  computed: {
    userInfo() {
      console.log(this.$store.state.user.userInfo);
      return this.$store.state.user.userInfo;
    },
    fullName: {
      // getter
      get: function () {
        return this.firstName + " " + this.lastName;
      },
      // setter
      set: function (newValue) {
        var names = newValue.split(" ");
        this.firstName = names[0];
        this.lastName = names[names.length - 1];
      },
    },
  },
  methods: {
    go(path) {
      this.$router.push({ name: path });
    },
    save() {
      if (this.passwordEnable && !this.password) {
        this.$toast(this.$t("login.meetPsdNo"));
        return;
      }
      if (this.ownerPasswordEnable && !this.ownerPassword) {
        this.$toast(this.$t("login.mainPwdNo"));
        return;
      }
      if (
        this.passwordEnable &&
        this.password &&
        !checkSmallPwd(this.password)
      ) {
        this.$toast(this.$t("reg.huiyiPwd"));
        return;
      }
      if (
        this.ownerPasswordEnable &&
        this.ownerPassword &&
        !checkSmallPwd(this.ownerPassword)
      ) {
        this.$toast(this.$t("reg.huiyiPwd"));
        return;
      }
      let data = {};
      data.password = this.password;
      data.passwordEnable = this.passwordEnable ? 1 : 0;
      data.ownerPassword = this.ownerPassword;
      data.ownerPasswordEnable = this.ownerPasswordEnable ? 1 : 0;
      data.allowEarlyEntry = this.allowEarlyEntry ? 1 : 0;
      data.muteJoinMeeting = this.muteJoinMeeting ? 1 : 0;
      console.log(data, 888, this.userInfo.conferenceNo);

      sdk.meetSetting
        .changePersonalMeetingInfo(this.userInfo.conferenceNo, data)
        .then((res) => {
          this.$store.commit("user/setUserInfo", {
            ...this.userInfo,
            ...data,
          });
          this.back();
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang='less'>
.meetSetting {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;
  .rightImg {
    width: 32px;
    height: 32px;
    margin-left: 16px;
  }
  .text {
    font-size: 28px;
    color: #999;
  }
  .header {
    height: 200px;
    margin-top: 16px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    .img {
      width: 110px;
      height: 110px;
    }
    .info {
      margin-left: 20px;
    }
    .name {
      font-size: 32px;
      color: #000;
    }
    .tel,
    .email {
      font-size: 28px;
      color: #999;
    }
  }
  .cell {
    margin-top: 16px;
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
    .bottomBtn {
      margin-top: 32px;
    }
  }

  .item {
    background: #fff;
    margin-top: 16px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .tit {
      font-size: 32px;
      color: #333;
    }
    .box {
      display: flex;
      align-items: center;
    }
  }
}
</style>
